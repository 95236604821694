import React, {useEffect, useState} from "react";
import DubaiImg from "../../assets/images/popup/Dubai.webp"
import SingaporeImg from "../../assets/images/popup/Singapore.webp"
import "./popup.css"

function PopUp() {

    const [isShow, setIsShow] = useState(true)
    const handleClickCountry = (country: string) => {
        localStorage.setItem("country", country)
        setIsShow(false)
    }

    useEffect(() => {
        document.body.style.position = isShow ? "fixed" : "initial";
    }, [isShow]);

    return <div className={`${isShow ? "flex" : "hidden"} justify-center items-center
                           fixed z-[100]
                           top-0 bottom-0 right-0 left-0
                           backdrop-blur-[30px]`}
            >
        <div
            className="
                    aspect-auto min-h-[361px]
                    flex flex-col justify-center items-center gap-[16px] lg:gap-[24px]
                    absolute z-[101] rounded-[22px] lg:rounded-xl bg-[#1C1A1C] overflow-hidden
                    m-[16px] p-[16px]"
            >

            <span className="text-[#FFF] text-[24px] lg:text-[36px] tracking-[-2px] font-[400]">Choose <span className="text-[#BDBDBD]">a branch</span></span>

            <div className="flex flex-col lg:flex-row gap-[24px]">

                <div className="card_hover aspect-auto max-w-[400px] max-h-[225px] relative cursor-pointer" onClick={()=>handleClickCountry("Singapore")}>
                    <img src={SingaporeImg} alt="SingaporeImg" className="spec_blur specobject-cover rounded-[12px]"/>
                    <div className="flex flex-col items-center justify-center
                                    absolute top-[50%] left-[50%]
                                    translate-x-[-50%] translate-y-[-50%]
                                    "
                    >
                        <span style={{textShadow: "0px 0px 8px rgba(0, 0, 0, 0.16)"}} className="text-[#ffff] text-[36px] font-[500]">Singapore</span>
                        <span style={{textShadow: "0px 0px 8px rgba(0, 0, 0, 0.16)"}} className="text-[#ffff] text-[28px] font-[500] leading-[34px]">GMT+8</span>
                    </div>
                </div>

                <div className="card_hover max-w-[400px] max-h-[225px] relative cursor-pointer">
                    <img src={DubaiImg} alt="DubaiImg" className="spec_blur object-cover rounded-[12px]"/>
                    <div className="flex flex-col items-center justify-center
                                    absolute top-[50%] left-[50%]
                                    translate-x-[-50%] translate-y-[-50%]
                                    "
                    >
                        <span style={{textShadow: "0px 0px 8px rgba(0, 0, 0, 0.16)"}} className="text-[#ffff] text-[36px] font-[500]">Dubai</span>
                        <span style={{textShadow: "0px 0px 8px rgba(0, 0, 0, 0.16)"}} className="text-[#ffff] text-[28px] font-[500] leading-[34px]">GMT+4</span>
                    </div>

                    <span className="special_text hidden
                                     text-[#ffff] text-[28px] font-[500] leading-[34px] w-max
                                     absolute top-[85%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                    >
                            Coming soon
                    </span>

                </div>

            </div>

            <div style={{
                position: "absolute",
                bottom: "-53px",
                left: "-171px",
                width: "500px",
                height: "299px",
                transform: "rotate(-166deg)",
                flexShrink: "0",
                borderRadius: "3000px",
                opacity:" 0.65",
                background: "linear-gradient(180deg,#538dfe 0%,rgba(0, 0, 0, 0) 100%,rgba(0, 0, 0, 0) 100%)",
                filter: "blur(50px)",
                zIndex: "-1",
                backgroundAttachment: "fixed",
            }}/>
            <div
                style={{
                    position: "absolute",
                    top: "-53px",
                    right: "-171px",
                    width: "500px",
                    height: "299px",
                    transform: "rotate(45deg)",
                    flexShrink: "0",
                    borderRadius: "3000px",
                    opacity:" 0.65",
                    background: "linear-gradient(180deg,#538dfe 0%,rgba(0, 0, 0, 0) 100%,rgba(0, 0, 0, 0) 100%)",
                    filter: "blur(50px)",
                    zIndex: "-1",
                    backgroundAttachment: "fixed",
                }}
            />

        </div>

    </div>
}

export default PopUp