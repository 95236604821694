import React from "react";
import TitleContainer from "../ui/TitleContainer/TitleContainer";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";
import openxIcon from "../../assets/icons/platforms/OpenX.svg";
import AdRiver from "../../assets/icons/platforms/adr.svg";
import Between from "../../assets/icons/platforms/between_exchange.svg";
import googleadx from "../../assets/icons/platforms/googleadx.svg";
import googleadmob from "../../assets/icons/platforms/googleadmob.svg";
import Xandr from "../../assets/icons/platforms/xandr.svg";
import pubmatic from "../../assets/icons/platforms/pubmatic.svg";
import IconContainer from "../ui/IconContainer/IconContainer";
import { Flex } from "@radix-ui/themes";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay } from "swiper/modules";

function Platforms() {
    const title = "Platforms";
    const label = "Platforms";
    const platformList = [
        {
            title: "Google-AdX",
            icon: googleadx,
        },
        {
            title: "Google-AdMob",
            icon: googleadmob,
        },
        {
            title: "PubMatic",
            icon: pubmatic,
        },
        {
            title: "OpenX",
            icon: openxIcon,
        },
        {
            title: "xandr",
            icon: Xandr,
        },
        {
            title: "between-exchange",
            icon: Between,
        },
        {
            title: "AdRiver",
            icon: AdRiver,
        },
    ];

    const isLargeScreen = window.innerWidth >= 1024;

    return (
        <ComponentContainer separator>
            <TitleContainer title={title} label={label} color="#FFFFFF" />
            {isLargeScreen ? (
                <Flex className="justify-center flex-wrap gap-[24px]">
                    {platformList.map((platform, index) => (
                        <div key={index} className="max-w-[200px]">
                            <IconContainer icon={platform.icon} />
                        </div>
                    ))}
                </Flex>
            ) : (
                <Flex className="h-[100px]">
                    <Swiper
                        style={{overflow: "hidden"}}
                        slidesPerView={"auto"}
                        spaceBetween={24}
                        freeMode={true}
                        autoplay={{
                            pauseOnMouseEnter: true,
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        modules={[FreeMode, Autoplay]}
                    >
                        {platformList.map((platform, index) => (
                            <SwiperSlide key={index} className="max-w-[200px]">
                                <IconContainer
                                    key={index}
                                    icon={platform.icon}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Flex>
            )}
        </ComponentContainer>
    );
}

export default Platforms;
