import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import ArrowIcon from "../../assets/icons/ArrowIcon.svg";
import SingaporeIcon from "../../assets/icons/SingaporeIcon.svg";
import DubaiIcon from "../../assets/icons/DubaiIcon.svg";
import React, {useState} from "react";

const DropDownCountry = () => {

    const localCountry = localStorage.getItem("country") || "Singapore"
    const [country, setCountry] = useState<string>(localCountry)
    const countryIcon: { [key: string]: string} = {"Singapore": SingaporeIcon, "Dubai": DubaiIcon}
    const handleChangeCountry = (country: string) => {
        localStorage.setItem("country", country)
        setCountry(country)
    }

   return(
       <div className="
                flex items-center justify-center flex-col gap-[8px]
                md:w-[200px] md:justify-normal md:flex-row"
       >
            <span className="text-[16px] text-[#BDBDBD] font-[400]">
               Branch:
            </span>

           <DropdownMenuPrimitive.Root>
               <DropdownMenuPrimitive.Trigger asChild>
                                        <span className="flex items-center gap-[4px] text-[16px] text-[#fff] font-[500] cursor-pointer">
                                            <div className="w-[27px] h-[16px]">
                                                <img src={countryIcon[country]} alt={`${country}`}/>
                                            </div>
                                            {country}
                                            <img src={ArrowIcon} alt={`arrowIcon`}/>
                                        </span>
               </DropdownMenuPrimitive.Trigger>
               <DropdownMenuPrimitive.Portal>
                   <DropdownMenuPrimitive.Content
                       sideOffset={0}
                       alignOffset={0}
                       style={{
                           padding:"16px",
                           marginTop: "16px",
                           borderRadius: "12px",
                           backgroundColor: "#1C1A1C"
                       }}
                       className={
                           "px-[32px] py-[24px] " +
                           "flex flex-col items-start gap-[24px] " +
                           "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down " +
                           "z-10 bg-white bg-opacity-5 backdrop-blur-[20px]"
                       }
                   >
                       <DropdownMenuPrimitive.Item
                           onClick={() => handleChangeCountry("Singapore")}
                           className="focus:outline-none"
                       >
                                                    <span className="flex items-center gap-[4px] text-[16px] text-[#fff] font-[500] cursor-pointer">
                                                        <div className="w-[27px] h-[16px]">
                                                            <img src={SingaporeIcon} alt="Singapore"/>
                                                        </div>
                                                        Singapore
                                                    </span>
                       </DropdownMenuPrimitive.Item>
                       <DropdownMenuPrimitive.Item
                           // onClick={() => handleChangeCountry("Dubai")}
                           className="focus:outline-none "
                       >
                                                    <span className="flex items-center gap-[4px] text-[16px] text-[#fff] font-[500] cursor-pointer">
                                                        <div className="w-[27px] h-[16px]">
                                                            <img src={DubaiIcon} alt="Dubai"/>
                                                        </div>
                                                        Dubai
                                                    </span>
                       </DropdownMenuPrimitive.Item>
                   </DropdownMenuPrimitive.Content>
               </DropdownMenuPrimitive.Portal>
           </DropdownMenuPrimitive.Root>
       </div>
   )
}

export default DropDownCountry