import React from "react";
import TitleContainer from "../ui/TitleContainer/TitleContainer";
import karar from "../../assets/icons/partners/Karar.svg";
import kolesa from "../../assets/icons/partners/kolesa.svg";
import zakon from "../../assets/icons/partners/zakon.svg";
import ficbook from "../../assets/icons/partners/ficbook.svg";

import IconContainer from "../ui/IconContainer/IconContainer";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";
import { Flex } from "@radix-ui/themes";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay } from "swiper/modules";

import "swiper/css";
import "../../App.css";
const Partners = () => {
    const title = "Our partners";
    const label = "Partners";
    const partnersList = [karar, kolesa, zakon, ficbook];
    return (
        <ComponentContainer separator>
            <TitleContainer title={title} label={label} color="#FFFFFF" />
            <Flex className="h-[100px]">
                <Swiper
                    style={{overflow: "hidden"}}
                    slidesPerView={"auto"}
                    spaceBetween={24}
                    freeMode={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    modules={[FreeMode, Autoplay]}
                >
                    {partnersList.map((element, index) => (
                        <SwiperSlide key={index} className="max-w-[200px]">
                            <IconContainer icon={element} />
                        </SwiperSlide>
                    ))}
                    <SwiperSlide className="max-w-[200px]">
                        <div
                            style={{
                                borderRadius: "22px",
                                minWidth: "200px",
                                minHeight: "100px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.05)",
                            }}
                        >
                            <span className="text-white leading-normal uppercase font-manrope font-medium text-2xl font-normal tracking-wider">5 000+</span>
                            <span className="text-[#BDBDBD] text-[16px] leading-[22px] font-family-Manrepo ">websites and apps</span>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Flex>
        </ComponentContainer>
    );
};

export default Partners;
