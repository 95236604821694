import React from "react";
import TitleContainer from "../ui/TitleContainer/TitleContainer";
import ImageCard from "../ui/ImageCard/ImageCard";
import kz from "../../assets/images/countries/Kazakhstan.webp";
import uz from "../../assets/images/countries/Uzbekistan.webp";
import tk from "../../assets/images/countries/Turkey.webp";
import sg from "../../assets/images/countries/Singapore.webp";
import uae from "../../assets/images/countries/Dubai.webp";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";
import { Flex } from "@radix-ui/themes";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay } from "swiper/modules";

function Worldwide() {
    //const currentPath = useLocation();
    const currentPath = { pathname: "/contact" };
    const title =
        currentPath.pathname !== "/contact"
            ? "Worldwide Rave Reviews"
            : "Locations";
    const label = currentPath.pathname !== "/contact" ? "Reviews" : "Locations";
    const countryList = [
        {
            text: {
                title: "Singapore",
                text2: "website.kz",
                text3: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            },
            locationText: {
                title: "Singapore",
                text2: "659526, Singapore, 2021, 23 Bukit Bator str. #03-206",
                text3: "+65 8338 7295",
            },
            image: sg,
        },
        {
            text: {
                title: "Kazakhstan",
                text2: "website.kz",
                text3: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            },
            locationText: {
                title: "Kazakhstan",
                text2: ["Kazakhstan, Almaty", "Zhibek Zholy 135, Block 3, 9th floor"],
                text3: "+7 727 339 0300 / +7 700 122 2224",
            },
            image: kz,
        },
        {
            text: {
                title: "Uzbekistan",
                text2: "website.kz",
                text3: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            },
            locationText: {
                title: "Uzbekistan",
                text2: "Uzbekistan, Tashkent, Mukimi, 7/1",
                text3: "+998 (90) 813-08-58",
            },
            image: uz,
        },
        {
            text: {
                title: "Dubai",
                text2: "website.kz",
                text3: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            },
            locationText: {
                title: "Dubai",
                text2: "UAE, Dubai",
                text3: "",
            },
            image: uae,
        },
    ];

    return (
        <ComponentContainer separator>
            <TitleContainer title={title} label={label} color="#FFFFFF" />
            <Flex className="min-h-[160px]">
                <Swiper
                    style={{overflow: "hidden"}}
                    slidesPerView={"auto"}
                    spaceBetween={24}
                    freeMode={true}
                    autoplay={{
                        pauseOnMouseEnter: true,
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    modules={[FreeMode, Autoplay]}
                >
                    {countryList.map((country, index) => (
                        <SwiperSlide key={index} className="max-w-[260px]">
                            <div className="swiper-no-swiping">
                                <ImageCard
                                    text={
                                        currentPath.pathname !== "/contact"
                                            ? country.text
                                            : country.locationText
                                    }
                                    image={country.image}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Flex>
        </ComponentContainer>
    );
}

export default Worldwide;
