import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/icons/logo_TDS.svg";
import DubaiIcon from "../../assets/icons/DubaiIcon.svg"
import SingaporeIcon from "../../assets/icons/SingaporeIcon.svg"
import MenuIcon from "../../assets/icons/menu.svg";
import ArrowIcon from "../../assets/icons/ArrowIcon.svg";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";
import { Flex } from "@radix-ui/themes";
import DropdownMenu from "../DropDownMenu/DropDownMenu";
import DropDownCountry from "../DropDownCountry/DropDownCountry";

const NavLink = ({
    to,
    label,
    currentPath,
}: {
    to: string;
    label: string;
    currentPath: string;
}) => {
    const isActive = currentPath === to;

    return (
        <Link
            to={to}
            className={`z-10 hover:text-white text-base font-normal ${
                isActive ? "text-white" : "text-gray-400"
            }`}
        >
            {label}
        </Link>
    );
};

function Header() {
    const currentPath = useLocation();


    return (
        <header className="bg-white bg-opacity-5 backdrop-blur-[10px] h-[84px] fixed w-full flex align-center z-10">
            <ComponentContainer separator={false}>
                <Flex className="flex flex-row justify-between h-full items-center">
                    <Link to="/">
                        <img src={Logo} alt="Logo" className="ImgDrag" />
                    </Link>
                    <div className="hidden md:visible md:flex flex-row justify-between items-center gap-12">
                        <nav className="flex gap-12">
                            <NavLink
                                to="/"
                                label="Home"
                                currentPath={currentPath.pathname}
                            />
                            <NavLink
                                to="/agreement"
                                label="Agreement"
                                currentPath={currentPath.pathname}
                            />
                            <NavLink
                                to="/products"
                                label="Products"
                                currentPath={currentPath.pathname}
                            />
                            <NavLink
                                to="/company"
                                label="Company"
                                currentPath={currentPath.pathname}
                            />

                            <DropDownCountry/>

                            <NavLink
                                to="/contact"
                                label="Contact"
                                currentPath={currentPath.pathname}
                            />
                        </nav>
                        <a
                            href="https://reports.tds.bid/login"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-[#538DFE] hover:bg-blue-600 text-white h-12 w-24 rounded-md text-sm flex items-center justify-center"
                        >
                            <span className="text-[16px]">Login</span>
                        </a>
                    </div>
                    <div className="visible md:hidden ">
                        <DropdownMenu />
                    </div>
                </Flex>
            </ComponentContainer>
        </header>
    );
}

export default Header;


