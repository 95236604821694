import React, {useState} from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import MenuIcon from "../../assets/icons/menu.svg";
import {Link} from "react-router-dom";
import DropDownCountry from "../DropDownCountry/DropDownCountry";

const DropdownMenu = () => {
    const [openNav, setOpenNav] = useState(false);
    return (
        <DropdownMenuPrimitive.Root>
            <DropdownMenuPrimitive.Trigger asChild>
                <button
                    onClick={() => setOpenNav(!openNav)}
                    className="bg-blue-500 rounded-[12px] h-[48px] w-[48px] px-[12px] flex justify-center items-center"
                >
                    <img
                        src={MenuIcon}
                        className="text-white bg-blue w-[18px]"
                    />
                </button>
            </DropdownMenuPrimitive.Trigger>
            {openNav && (
                <DropdownMenuPrimitive.Portal>
                    <DropdownMenuPrimitive.Content
                        sideOffset={0}
                        alignOffset={0}
                        style={{
                            marginTop: "22px",
                            marginRight: "24px",
                        }}
                        className={
                            "px-[32px] py-[24px] flex flex-col items-center gap-[24px] radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down z-10 bg-white bg-opacity-5 backdrop-blur-[20px]"
                        }
                    >
                        <DropdownMenuPrimitive.Item
                            onClick={() => setOpenNav(!openNav)}
                            className="focus:outline-none"
                        >
                            <Link
                                to="/"
                                className="text-white text-base font-normal "
                            >
                                <span>Home</span>
                            </Link>
                        </DropdownMenuPrimitive.Item>
                        <DropdownMenuPrimitive.Item
                            onClick={() => setOpenNav(!openNav)}
                            className="focus:outline-none "
                        >
                            <Link
                                to="/products"
                                className="text-white text-base font-normal"
                            >
                                <span>Products</span>
                            </Link>
                        </DropdownMenuPrimitive.Item>
                        <DropdownMenuPrimitive.Item
                            onClick={() => setOpenNav(!openNav)}
                            className="focus:outline-none "
                        >
                            <Link
                                to="/company"
                                className="text-white text-base font-normal"
                            >
                                <span>Company</span>
                            </Link>
                        </DropdownMenuPrimitive.Item>

                        {/* select country*/}

                        <DropDownCountry/>

                        {/*----------------*/}
                        <DropdownMenuPrimitive.Item
                            onClick={() => setOpenNav(!openNav)}
                            className="focus:outline-none "
                        >
                            <Link
                                to="/contact"
                                className="text-white text-base font-normal"
                            >
                                <span>Contact</span>
                            </Link>
                        </DropdownMenuPrimitive.Item>
                        <DropdownMenuPrimitive.Item
                            onClick={() => setOpenNav(false)}
                            className="focus:outline-none"
                        >
                            <a
                                href="https://reports.tds.bid/login"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-[#538DFE] rounded-[12px] hover:bg-blue-600 text-white h-[42px] w-[84px] rounded-[12px] text-sm flex items-center justify-center"
                            >
                                <span>Login</span>
                            </a>
                        </DropdownMenuPrimitive.Item>
                    </DropdownMenuPrimitive.Content>
                </DropdownMenuPrimitive.Portal>
            )}
        </DropdownMenuPrimitive.Root>
    );
};

export default DropdownMenu