import React from "react";
import TitleContainer from "../ui/TitleContainer/TitleContainer";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";
import photo1 from "../../assets/images/team/1.webp";
import photo2 from "../../assets/images/team/2.webp";
import photo3 from "../../assets/images/team/3.webp";
import photo4 from "../../assets/images/team/4.webp";
import photo5 from "../../assets/images/team/5.webp";
import photo7 from "../../assets/images/team/7.webp";
import photo9 from "../../assets/images/team/9.webp";
import { Flex } from "@radix-ui/themes";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay } from "swiper/modules";

function Leadership() {
    const title = "Leadership";
    const label = "Team";
    const teamList = [
        {
            image: photo1,
            fullName: "Mukhtar Mukhtarov",
            pos: "Managing Partner",
        },
        {
            image: photo2,
            fullName: "Azret Akhmetov",
            pos: "CEO",
        },
        {
            image: photo3,
            fullName: "Artyom Sitnikov",
            pos: "Manager",
        },
        {
            image: photo4,
            fullName: "Yulia Rakhimzhanova",
            pos: "HR",
        },
        {
            image: photo5,
            fullName: "Bohodir Daikoziyev",
            pos: "Developer",
        },
        {
            image: photo7,
            fullName: "Asiya Kilybayeva",
            pos: "Manager",
        },
        {
            image: photo9,
            fullName: "Dora Brown",
            pos: "Manager",
        },
    ];
    return (
        <ComponentContainer separator>
            <TitleContainer title={title} label={label} color="white" />

            {/* <Flex wrap="nowrap" className="ScrollBar overflow-x-auto justify-between gap-[24px]" >
                {teamList.map((member, index) => (
                    <Card key={index} image={member.image} fullName={member.fullName} pos={member.pos} />
                ))}
            </Flex> */}
            <Flex className="h-auto">
                <Swiper
                    style={{overflow: "hidden"}}
                    slidesPerView={"auto"}
                    spaceBetween={40}
                    freeMode={true}
                    autoplay={{
                        pauseOnMouseEnter: true,
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[FreeMode, Autoplay]}
                >
                    {teamList.map((member, index) => (
                        <SwiperSlide key={index} className="max-w-[165px]">
                            <Card
                                image={member.image}
                                fullName={member.fullName}
                                pos={member.pos}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Flex>
        </ComponentContainer>
    );
}

export default Leadership;

const Card = ({
    image,
    fullName,
    pos,
}: {
    image: string;
    fullName: string;
    pos: string;
}) => {
    return (
        <div
            className="gap-[10px]"
            style={{
                minWidth: "162px",
                minHeight: "220px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "left",
                textAlign: "left",
            }}
        >
            <img
                src={image}
                width="160px"
                height="160px"
                className="ImgDrag flex-row rounded-[12px]"
            />
            <h5 className="font-[22px] text-white leading-[22px]">
                {fullName}
            </h5>
            <h6 className="font-[16px] text-[#BDBDBD] leading-[22px]">{pos}</h6>
        </div>
    );
};
